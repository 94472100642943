import * as React from 'react'
import Marquee from 'react-fast-marquee'
import styled from '@xstyled/styled-components'
import { useAnnouncement } from '../providers/AnnouncementProvider'
import { RichText } from './RichText'
import { PageLink } from './PageLink'

const AnnouncementWrapper = styled.div<{
  $scrolled?: boolean
  $colorTheme: string
}>`
  position: fixed;
  left: 0;
  right: 0;
  background-color: ${({ $colorTheme }) =>
    $colorTheme == 'light' ? '#f5f3f4' : '#444443'};
  color: ${({ $colorTheme }) => ($colorTheme == 'light' ? 'body.7' : '#fff')};
  height: 40px;
  display: flex;
  align-items: center;
  z-index: ${({ theme }) => theme.zIndices.nav + 1};
  top: ${({ $scrolled }) => ($scrolled ? '-40px' : '0')};
  transition: top 0.3s ease;
  will-change: top;
`

const MarqueeContent = styled.div`
  display: flex;
  align-items: center;
  font-family: sans;
  font-size: 5;
  letter-spacing: 0.5px;
  padding: 0 4;
  cursor: pointer;

  /* Style adjustments for RichText content */
  > div {
    display: flex;
    align-items: center;
  }

  p {
    margin: 0;
  }
`

const CloseButton = styled.button<{ $colorTheme: string }>`
  position: absolute;
  right: 4;
  top: 50%;
  transform: translateY(-50%);
  color: ${({ $colorTheme }) => ($colorTheme == 'light' ? 'body.7' : '#fff')};
  padding: 2;
  z-index: 2;
  font-size: 5;

  &:hover {
    opacity: 0.8;
  }
`

export const MarqueeAnnouncement: React.FC = () => {
  const {
    visible,
    message,
    showCloseButton,
    setVisible,
    enableMarquee,
    colorTheme,
    cta,
  } = useAnnouncement()

  const [scrolled, setScrolled] = React.useState(false)

  React.useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 40
      setScrolled(isScrolled)
    }

    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  const content = (
    <MarqueeContent>
      <RichText body={message} />
    </MarqueeContent>
  )

  if (!visible) return null

  return (
    <AnnouncementWrapper
      $scrolled={scrolled ? scrolled : undefined}
      $colorTheme={colorTheme || 'light'}
    >
      <Marquee
        gradient={true}
        gradientColor={colorTheme == 'light' ? '#f5f3f4' : '#444443'}
        gradientWidth={100}
        speed={50}
        pauseOnHover
      >
        {cta ? <PageLink link={cta[0]}>{content}</PageLink> : content}
      </Marquee>
      {showCloseButton && (
        <CloseButton
          $colorTheme={colorTheme || 'light'}
          onClick={() => setVisible(false)}
        >
          ✕
        </CloseButton>
      )}
    </AnnouncementWrapper>
  )
}
